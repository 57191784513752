<template>
  <ion-page>
    <ion-content>
      <Sign
        :modeSign="'SignUp'"
        :emailStore="email"
        :sponsorCode="sponsorCode"
        >
        </Sign>
    </ion-content>
  </ion-page>
</template>

<script>
import store from '@/store'
import { IonPage,IonContent } from '@ionic/vue';
import Sign from '@/components/sign/Sign.vue'

export default  {
  name: 'SignUp',
  components: { Sign,IonContent, IonPage },
  data() {
    return {
      email: "",
      sponsorCode: '',

    }
  },
  mounted() {
    this.sponsorCode = this.$route.query.sponsor_code;
  },
  ionViewWillEnter() {
    this.email = store.getters.signEmail;
    window.$crisp.push(["do", "chat:hide"]);
  },
}
</script>
<style scoped>
ion-content {
  margin: auto;
}
</style>
